import "../../scss/pages/Features.scss"
import { Link } from "gatsby"

import React from "react"
import SEO from "../../components/marketing/seo"
import Header from "../../components/marketing/Header/Header.jsx"
import Footer from "../../components/marketing/Footer/Footer.jsx"

import RunwayIcon from '!svg-react-loader!../../../content/assets/icn-runway.svg?name=RunwayIcon'
import ExpensesIcon from '!svg-react-loader!../../../content/assets/icn-expense-tracking.svg?name=ExpensesIcon'
import TaxesIcon from '!svg-react-loader!../../../content/assets/icn-estimated-tax.svg?name=TaxesIcon'

import imgExpenseTrackingScreen from '../../../content/assets/img-expenses-screen.png'
import imgExpenseDeskLady from '../../../content/assets/img-expense-desk.png'
import MailingListSignup from "../../components/marketing/MailingListSignup/MailingListSignup.jsx"



class ExpenseTracking extends React.Component {
  render() {
    return (
      <React.Fragment>
        <SEO title={`Hammock - Features - Expense Tracking`}
             description={`Hammock automatically tracks and categorizes business expenses, so you can save time and money at tax time by staying organized.`}
             keywords={['expense tracking for freelancers', 'expense tracking', 'mint for freelancers', 'self-employed', 'selfemployed', 'freelance', 'freelancers', 'freelancing']}
        />

        <Header />

        <div className="feature-page container">
          <div className="row">
            <div className="feature-hero col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-12 col-xs-12">
              <ExpensesIcon className="feature-hero-icon" />
              <h1>Expense Tracking</h1>
              <p>Hammock calculates your runway based on your monthly paycheck, business expenses, and estimated taxes.</p>
            </div>
          </div>
        </div>

        <div className="taxes-are-hard section grey px-4 px-sm-0">
          <div className="container">
            <div className="row">
              <div className="col-md-5 pb-5 pb-md-0">
                <h2>Automatically Track Business Expenses and Income</h2>
                <p>Hammock automatically and securely pulls in your bank account transactions. Then, you can categorize them as various business write-offs to help you save money at tax time. Stop updating that pesky spreadsheet and let us automate it for you.</p>
                <a className="" href="https://app.tryhammock.com/signup" title="Signup for a 14-day FREE Trial">Sign Up for a 14-Day FREE Trial &#10230;</a>
              </div>
              <div className="col-lg-5 offset-lg-2 col-md-6 offset-md-1 col-sm-12">
                <img className="tax-airplane-lady" src={imgExpenseDeskLady} alt="With Hammock, you no longer need to keep your outdated spreadsheet up to date with business expenses."/>
              </div>
            </div>
          </div>
        </div>


        <div className="large-feature-image section white px-4 px-sm-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1 col-md-12 col-sm-12 col-xs-12">
                <img className="feature-laptop-screenshot" src={imgExpenseTrackingScreen} alt="Hammock helps you track and manage your business expenses, so you'll know where you stand when tax season comes around." />
              </div>
            </div>
          </div>
        </div>

        <div className="other-features section white px-4 px-sm-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3 col-md-12 col-sm-12 col-xs-12">
                <h6 className="section-heading">Other Features</h6>
                <div className="row">
                  <div className="col-6">
                    <Link to="/features/runway" className="feature-card">
                      <RunwayIcon className="feature-icon" />
                      <p>Runway Insights</p>
                    </Link>
                  </div>

                  <div className="col-6">
                    <Link to="/features/estimated-taxes" className="feature-card">
                      <TaxesIcon className="feature-icon" />
                      <p>Estimated Taxes</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <MailingListSignup signupLocation="Feature Page: Expense Tracking"/>

        <Footer />
      </React.Fragment>
    )
  }
}

export default ExpenseTracking
